.logo-spin {
    width: 100px;
}
.loader {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, .3);
}

.loader p {
    font-size: 16px;
    color: black;
    margin-top: 16px;
    z-index: 99999;
}