.ModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal {
  z-index: 100;
  background: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  max-width: 500px;
  padding: 2rem;
}

.modalBold {
  text-align: center;
}

.modalX {
  background-color: rgb(255, 255, 255);
  color: #0D3693;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  border: none;
  border-radius: 30px;
}

.error-text {
  font-size: 12px;
  color: red;
}

.modalBold {
  color: rgb(85, 201, 149);
}

.Footer {
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.modalButton {
  background-color: rgb(240, 239, 239);
  color: #0D3693;
  font-size: 20px;
  border: 0px;
  border-radius: 40px;
  padding: 10px;
  cursor: pointer;
}

.modalBody {
  text-align: center;
}

.modalInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}