.ModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1040;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .DeleteModalContent {
    background-color: #fff;
    width: 500px; 
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: relative;
    border-radius: 5px;
  }

  .DeleteTitle{
    color: rgb(85, 201, 149);
    text-align: center;
  }

  .DeleteText{
    text-align: center;
  }

  .DeleteFooter{
    display: flex;
    justify-content: space-between;
  }
  
  .CloseButton {
    background-color: rgb(255, 255, 255);
    color: #0D3693;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
    border: none;
    border-radius: 30px;
  }

  .DeleteButton{
    background-color: rgb(151, 0, 0);
    color: #ffffff;
    font-size: 20px;
    border: 0px;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    }