h1.userTitle{
    font-size: 50px;
    color:#55C995;
    display:flex;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 0;
}

h2.userText{
    font-size: 20px;
    color:#0D3693;
    display:flex;
    justify-content: center;
}

