

h1.homeTitle{
    font-size: 50px;
    color:#55C995;
    display:flex;
    justify-content: center;
    margin-top: 10%;
    margin-bottom: 0%;
}

h2.unsubscribe{
    font-size: 25px;
    color:#6C695F;
    display: flex;
    justify-content: center;
}

p.Notification{
    font-size: 20px;
    color:#C7C7C7;
    display: flex;
    justify-content: center;
}

.button{
    background-color: #55C995;
    border: none;
    color: white;
    padding: 15px;
    font-size: 20px;
    display:flex;
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
    margin-top: 5px;
    cursor: pointer;
}

*{
    font-family: 'DM Sans', sans-serif;
}
