.ModalContainer{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0,0,0,0.6);
}

.modal {
    z-index: 100;
    background: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 30px;
    max-width: 500px;
    padding: 2rem;
  }

.modalBold{
    text-align: center;
}

.modalX{
    background-color: rgb(255, 255, 255);
    color: #0D3693;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}

.modalBold{
    color: rgb(85, 201, 149);
}

.Footer{
    text-align: center;
    display: flex;
    justify-content: space-between;

}
.modalButton{
background-color: rgb(240, 239, 239);
color: #0D3693;
font-size: 20px;
border: 0px;
border-radius: 40px;
padding: 10px;
cursor: pointer;
}

.modalBody{
    text-align: center;  
}

/* Need to create global modal css file
 and find all things like below that are used by all modals */
.visually-hidden {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
