.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.EditModalContent {
  background-color: #fff;
  width: 500px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.EditTitle {
  color: rgb(85, 201, 149);
  text-align: center;
}

.EditText {
  margin-bottom: 10px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%; 
  box-sizing: border-box; 
}

.EditFooter {
  display: flex;
  justify-content: space-between; 
  margin-top: 20px;
}

.CloseButton {
  background-color: rgb(255, 255, 255);
  color: #0D3693;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  padding: 8px 15px;
}

.CancelButton{
  background-color: rgb(236, 236, 236);
  color: #0D3693;
  font-size: 20px;
  border: 0px;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
}

.EditButton {
  background-color: rgb(85, 201, 149);
  color: #ffffff;
  font-size: 20px;
  border: 0px;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
}

.ErrorMessage {
  font-size: 12px;
  color: red;
}