h1.adminTitle{
    font-size: 40px;
    color:#55C995;
    text-align: center;
}

h2.instructions{
    color:#0D3693;
}

p.adminText{
    font-size: 15px;
    color:black;
    line-height: 1;
}

.notTitle{
    margin-left: 22%;
}

