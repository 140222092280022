.addBtns{
  display:flex;
  justify-content: center;
  margin-top: 20px;
}

button.addApp {
    display: flex;
    align-items: center;
    width: 125px;
    height: 50px;
    background-color: #55C995;
    border-radius: 30px;
    letter-spacing: 0.5px;
    color: white;
    font-size: 15px;
    cursor: pointer;
    margin-right: 15px;
    border: 0;
  }

  button.addNotification{
    display: flex;
    align-items: center;
    width: 125px;
    height: 50px;
    background-color: #55C995;
    border-radius: 30px;
    letter-spacing: 0.5px;
    color: white;
    font-size: 15px;
    cursor: pointer;
    border: 0;
  }
  
  button.add svg {
    margin-right: 5px; 
  }