.AdminTable {
    width: 50%;
    margin: 0 auto;
    border-collapse: collapse;
  }
  
  .TableHead {
    background-color: #f2f2f2;
  }
  
  .TableHead th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  .TableBody td {
    border: 1px solid #ddd;
    padding: 8px;
    /* text-align: left; */
  }
  
  .TableBody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .TableBody tr:hover {
    background-color: #f5f5f5;
  }

  .cadences{
    text-align: right;
  }

   .ActionButtons{
    display: flex;
    justify-content: center ;
  }

  .EditBtn{
    width: fit-content;
    height: fit-content;
    letter-spacing: 0.5px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    cursor: pointer;
    background-color: #55C995;
    padding: 5px;
    border-radius: 5px;
    border: 0;
    margin-right: 5px;
  }

  .DeleteBtn{
    width: fit-content;
    height: fit-content;
    letter-spacing: 0.5px;
    color: rgb(255, 255, 255);
    font-size: 12px;
    cursor: pointer;
    background-color: rgb(165, 51, 51);
    padding: 5px;
    border-radius: 5px;
    border: 0;
  }

  