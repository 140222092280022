.UserTable {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 50px;
    border-collapse: collapse;
  }
  
  .UserHeader {
    background-color: #f2f2f2;
  }
  
  .UserHeader th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  .UserBody td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .frequencies{
    text-align: right;
  }
  
  .UserBody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .UserBody tr:hover {
    background-color: #f5f5f5;
  }

  .subUnSubBtns{
    display: flex;
    justify-content: center ;
  }

  /* .CadenceBtn{
    width: fit-content;
    height: fit-content;
    letter-spacing: 0.5px;
    color: rgb(255, 255, 255);
    font-size: 10px;
    cursor: pointer;
    background-color: rgba(94, 86, 86, 0.568);
    padding: 5px;
    border-radius: 5px;
    border: 0;
  } */

  .UnsubBtn{
    width: 75px;
    height: 23px;
    letter-spacing: 0.5px;
    color: rgb(255, 255, 255);
    font-size: 10px;
    cursor: pointer;
    background-color: rgb(145, 50, 50);
    padding: 5px;
    border-radius: 5px;
    border: 0;
  }

  .SubBtn{
    width: 75px;
    height: 23px;
    letter-spacing: 0.5px;
    color: rgb(255, 255, 255);
    font-size: 10px;
    cursor: pointer;
    background-color: #55C995;
    padding: 5px;
    border-radius: 5px;
    border: 0;
  }
