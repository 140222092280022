body {
    margin: 0;
}

.title{
    font-size: 2rem;
}

.nav{
    background-color: #0D3693;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    align-items: center;
}

.nav a{
    display: flex;
}

.logo {
    width: 300px;
}








